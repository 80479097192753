import React from 'react'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import { Adsense } from '@components/Adsense/Adsense'
import { Box, Label, Input, Textarea, Button, Message, Spinner, Text} from 'theme-ui'
import { Link } from 'gatsby';


const PagePrestamosNacion = props => {
  

  return (
    <Layout {...props}>
      <Seo title='Prestamos-Nacion' />
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2292291997787849"
     crossOrigin="anonymous"></script>
      <Divider />
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          header='Préstamos Personales de Nacion 💸'
          subheader='Préstamos Banco Nación: cómo acceder hasta $5.000.000, paso a paso'
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
      <Link to="/solicitar-tarjeta-de-credito-bbva">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Tarjeta de Crédito BBVA 🤑 </Button>
        </Link>
        <Link to="/prestamos-personales">
          <Button style={{margin: '5px', width: '350px'}} variant="white">💰 Solicitá tu préstamo acá 💰 </Button>
        </Link>
        <Link to="/prestamos-personales-santander">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Préstamo de $150.000 🤑 </Button>
        </Link>
        <Link to="/solicitar-tarjeta-de-credito-santander">
          <Button style={{margin: '5px', width: '350px'}} variant="white">Solicita tu Visa Platinium 🤑 </Button>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='Acceder a financiación en Argentina es un desafío, especialmente debido a la elevada inflación y otras dificultades que obstaculizan la creación de líneas de crédito. Sin embargo, el Banco Nación ofrece préstamos con tasas de interés inferiores a las del mercado para satisfacer esta demanda.
    <br><br>  
          ¿Cuánto puedes obtener a través del Banco de la Nación?
          <br>  
          El monto máximo que el Banco Nación puede otorgar depende del tipo de préstamo solicitado. Para personas físicas, el límite es de $5.000.000, con un plazo de hasta 72 meses, utilizando el método de amortización francés, que consiste en una cuota fija mensual.'
        />
      </Stack>
      <Adsense />

      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='En el caso de individuos que necesiten dinero para "inversión" y "capital de trabajo", el plazo es de hasta 40 meses, con una tasa combinada anual fija del 40% durante los primeros años. <br>
          Por último, los jubilados, pensionados y aquellos que cobren sus haberes en el Banco Nación pueden solicitar préstamos de hasta $1.000.000, con plazos de 24 a 48 meses y una tasa fija del 40% en el primer año y del 48% en el segundo.
          <br><br>
          ¿Cómo solicitar un préstamo en el Banco Nación?
          <br>
Según el Banco Nación, obtener un préstamo en su entidad es un proceso sencillo que consta de 3 pasos. En primer lugar, es necesario informarse sobre las características principales, requisitos y documentación necesaria. El segundo paso implica calcular la cuota, estimando los ingresos requeridos y el costo total del préstamo.
<br>
El tercer paso es solicitar el crédito y obtener la preaprobación. Para ello, debes completar el formulario, seleccionar la sucursal donde prefieres realizar el trámite y adjuntar la documentación solicitada. Posteriormente, el banco se pondrá en contacto contigo.
<br><br>
Otra opción para solicitar un préstamo es a través de la aplicación BNA+. En este caso, debes seguir estos pasos:<br>
- Inicia sesión en la aplicación BNA+ y accede a la sección "Tu banco" y luego a "Préstamos".<br>
- Haz clic en la opción "+" para solicitar un nuevo crédito.<br>
- Selecciona la línea de crédito que necesites, el monto, el destino del dinero y la opción de cancelación deseada.<br>
- Verifica todos los datos relacionados con tu préstamo, como el tipo de tasa y el costo financiero total. Si todo es correcto, acepta los términos y condiciones del servicio.<br>
- Durante el proceso, se te solicitará que valides tu identidad de forma biométrica mediante una selfie. Haz clic en "Continuar" para visualizar tu solicitud y, adicionalmente, recibirás un correo electrónico con todos los detalles.
          '
        />
      </Stack>
      <Adsense />
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://anses.blog/sorteo-procrear-2023-diciembre-ganadores-y-lo-que-viene-para-el-2024/" style={{ textDecoration:'none', }}>
        <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
          También puede interesarte: </span>Plan Procrear II: Créditos de $3.000.000 para la casa propia y oportunidad de sorteo en julio</Text>
        </Link>
      </div>
      <Stack effectProps={{ effect: 'fadeInDown' }}>
        <PageTitle
          subheader='
          ¿Qué tipos de préstamos ofrece el Banco Nación? <br>

El Banco Nación ofrece una variedad de préstamos, entre ellos: <br>

- Préstamos personales para empleados que reciben sus haberes en el banco.<br>
- Préstamos para empleados públicos nacionales, provinciales, municipales y comunales.<br>
- Préstamos con libre destino.<br>
- Adelanto de haberes.<br>
- Préstamos hipotecarios UVA.<br>
- Préstamos para destinos específicos, como programas Mi Compu, ampliación, refacción y terminación de viviendas, consolidación de deudas, entre otros.<br>
- Préstamos para jubilados y pensionados.<br><br>
¿Cómo saber si cumples con los requisitos para obtener un préstamo en el Banco Nación?<br>

Para obtener un préstamo en el Banco Nación, debes cumplir con una serie de requisitos. Por ejemplo, en el caso de los préstamos a tasa bonificada por los 40 años de democracia en el país, existen diferentes requerimientos.<br>

En el caso de personas físicas que necesitan el dinero para inversiones o capital de trabajo, el préstamo se otorga a sola firma, pero se requiere una garantía acorde al monto entregado.
          '
        />
      </Stack>
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
        <Link to="https://www.anses.blog/como-solicitar-el-nuevo-credito-de-1-millon-de-la-anses-para-trabajadores/" style={{ textDecoration:'none', }}>
          <Text style={{margin: '5px', width: '100%', height: '100%', fontWeight:'600'}}><span style={{fontWeight:'700'}}>
            También puede interesarte: </span>Nuevo Crédito de $1 Millón de la Anses para Trabajadores💫</Text>
        </Link>
      </div>
      <Adsense />

    </Layout>
  )
}

export default PagePrestamosNacion
